<template>
    <ul class="mainmenu">
        <li class="has-droupdown has-menu-child-item">
            <router-link to="/">
                首页
                <Icon name="plus" class="collapse-icon plus"/>
                <Icon name="minus" class="collapse-icon minus"/>
            </router-link>
        </li>
        <li><router-link to="/contact">关于我们</router-link></li>
        <li class="has-droupdown has-menu-child-item">
            <a href="#">
                招贤纳士
                <Icon name="plus" class="collapse-icon plus"/>
                <Icon name="minus" class="collapse-icon minus"/>
            </a>
            <ul class="submenu">
                <li><router-link to="/portfolio">我要做销售冠军</router-link></li>
                <li><router-link to="/portfolio-three-column">我要做采购达人</router-link></li>
                <li><router-link to="/portfolio-full-width">我要做技术狂人</router-link></li>
            </ul>
        </li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon}
    }
</script>