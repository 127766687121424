<template>
    <!-- Start Google MAp -->
    <div class="google-map-style-1 border p-5" id="container">

    </div>
    <!-- End Google MAp -->
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'

export default {
    name: 'Amap',
    mounted() {
        this.initAMap();
    },
    methods: {
        initAMap() {
            AMapLoader.load({
                key: 'abd9ecc63520c344cb230a27ad70ce7b', //设置您的key，在高德地图申请，不要用我的
                version: '2.0',
                plugins: ['AMap.ToolBar', 'AMap.Driving'],
                AMapUI: {
                    version: '1.1',
                    plugins: [],
                },
                Loca: {
                    version: '2.0',
                },
            })
                .then((AMap) => {
                    this.map = new AMap.Map('container', {
                        viewMode: '3D',
                        zoom: 18,
                        zooms: [2, 22],
                        center: [121.448145,31.309081],
                    })

                    var marker = new AMap.Marker({
                        position: new AMap.LngLat(121.448145,31.309081),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                        title: '上海澜栗信息技术有限公司',
                    });

                    marker.setLabel({
                        direction: 'right',
                        offset: new AMap.Pixel(-80, -30),  //设置文本标注偏移量
                        content: "上海澜栗信息技术有限公司", //设置文本标注内容
                    });

                    this.map.add(marker);


                })
                .catch((e) => {
                    console.log(e)
                })
        },
    }
}
</script>

<style lang="css" scoped>
    .hello{
        background-color: #bbb;
    }
</style>