<template>
    <Layout :footer-style="2">
        <!-- Start Slider Area -->
        <div class="demo-slider-area slider-area bg-transparent slider-style-1 pb--100 pt--70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-center">
                            <div class="react-image mb--20" data-aos="fade-up">
                                <img src="../assets/images/demo/badge-vue-js.png" alt="Doob Vue"/>
                            </div>
                            <h1 class="title display-two" data-aos="fade-up" data-aos-delay="100">
                                我们是一个大家族 <br/>
                                <span class="theme-gradient">专业</span> &
                                <span class="theme-gradient">专注</span>
                                <br/>
                                有需求欢迎来砸
                            </h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="150">
                                我们24小时服务，以优质的服务以及精准的方案，保证让你十分满意。
                            </p>
                            <div class="button-group" data-aos="fade-up" data-aos-delay="200">
                                <a class="btn-default btn-large round btn-icon" href="#demo">瞅一瞅
                                    <Icon name="arrow-down"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <Separator/>

        <!-- Start Our Demo -->
        <div class="rn-demo-area rn-section-gap" id="demo">
            <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="holiday case"
                            title="节日案例"
                            description="我们的案例覆盖了多种节日场景，包括春节、元宵节、女神节、劳动节等......"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-12 mt--40"
                         v-for="(page, index) in demoPages"
                         :key="index"
                         data-aos="fade-up"
                         :data-aos-delay="100 + index">
                        <div class="single-demo">
                            <router-link :to="page.url">
                                <div class="thumbnail">
                                    <img class="image-dark" :src="page.image" alt="Corporate images"/>
                                    <img class="image-light" :src="page.imageLight" alt="Corporate images"/>
                                </div>
                            </router-link>
                            <h4 class="title">
                                <router-link :to="page.url">{{ page.title }} <span v-if="page.badge" class="rn-badge-card">{{ page.badge }}</span></router-link>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Our Demo -->

        <Separator/>

        <!-- Start Inner Pages -->
        <div class="rn-inner-pages rn-section-gap">
            <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="rencently case"
                            title="近期新增案例"
                            description="涵盖了电商行业、医疗行业、传统制造行业等......"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12 mt--40"
                         v-for="(page, index) in innerPages"
                         :key="index"
                         data-aos="fade-up"
                         :data-aos-delay="100 + index">
                        <div class="single-demo">
                            <router-link :to="page.url">
                                <div class="thumbnail">
                                    <img class="image-dark" :src="page.image" alt="Corporate images"/>
                                    <img class="image-light" :src="page.imageLight" alt="Corporate images"/>
                                </div>
                            </router-link>
                            <h4 class="title">
                                <router-link :to="page.url">{{ page.title }}</router-link>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Inner Pages -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'

    export default {
        name: 'Demo',
        components: {Icon, Layout, Separator, SectionTitle},
        data() {
            return {
                demoPages: [
                    {
                        url: '/business-consulting',
                        image: require('@/assets/images/demo/business-consulting.png'),
                        imageLight: require('@/assets/images/demo/business-consulting-light.png'),
                        title: '春节方案',
                        badge: 'Hot',
                    },
                    {
                        url: '/business-consulting-2',
                        image: require('@/assets/images/demo/business-consulting-2.png'),
                        imageLight: require('@/assets/images/demo/business-consulting-2-light.png'),
                        title: '元宵节方案',
                        badge: 'Trend',
                    },
                    {
                        url: '/corporate',
                        image: require('@/assets/images/demo/corporate.png'),
                        imageLight: require('@/assets/images/demo/corporate-light.png'),
                        title: '女神节方案',
                        badge: 'Hot',
                    },
                    {
                        url: '/startup',
                        image: require('@/assets/images/demo/startup.png'),
                        imageLight: require('@/assets/images/demo/startup-light.png'),
                        title: '劳动节方案',
                        badge: 'Trend',
                    },
                    {
                        url: '/web-agency',
                        image: require('@/assets/images/demo/web-agency.png'),
                        imageLight: require('@/assets/images/demo/web-agency-light.png'),
                        title: '端午节方案',
                        badge: 'Hot',
                    },
                    {
                        url: '/international-consulting',
                        image: require('@/assets/images/demo/international-consulting.png'),
                        imageLight: require('@/assets/images/demo/international-consulting-light.png'),
                        title: '儿童节方案',
                        badge: 'Trend',
                    },
                    {
                        url: '/consulting',
                        image: require('@/assets/images/demo/consulting.png'),
                        imageLight: require('@/assets/images/demo/consulting-light.png'),
                        title: '端午节方案',
                        badge: 'Hot',
                    },
                    {
                        url: '/finance',
                        image: require('@/assets/images/demo/finance.png'),
                        imageLight: require('@/assets/images/demo/finance-light.png'),
                        title: '中秋节方案',
                        badge: 'Hot',
                    },
                    {
                        url: '/digital-agency',
                        image: require('@/assets/images/demo/digital-agency.png'),
                        imageLight: require('@/assets/images/demo/digital-agency-light.png'),
                        title: '国庆节方案',
                        badge: 'Hot',
                    },
                    
                ],
                innerPages: [
                    {
                        id: '1',
                        url: '/blog-grid',
                        image: require('@/assets/images/demo/blog-grid.png'),
                        imageLight: require('@/assets/images/demo/blog-grid-light.png'),
                        title: '正帆科技'
                    },
                    {
                        id: '2',
                        url: '/blog-grid-sidebar',
                        image: require('@/assets/images/demo/blog-grid-sidebar.png'),
                        imageLight: require('@/assets/images/demo/blog-grid-sidebar-light.png'),
                        title: '平安银行'
                    },
                    {
                        id: '3',
                        url: '/blog-list-view',
                        image: require('@/assets/images/demo/blog-list-view.png'),
                        imageLight: require('@/assets/images/demo/blog-list-view-light.png'),
                        title: '巴士'
                    },
                    {
                        id: '4',
                        url: '/blog-list-sidebar',
                        image: require('@/assets/images/demo/blog-list-sidebar.png'),
                        imageLight: require('@/assets/images/demo/blog-list-sidebar-light.png'),
                        title: '宝钢武炭'
                    },
                    {
                        id: '5',
                        url: '/blog-details/3',
                        image: require('@/assets/images/demo/blog-details.png'),
                        imageLight: require('@/assets/images/demo/blog-details-light.png'),
                        title: '中国边检'
                    },
                    {
                        id: '6',
                        url: '/portfolio',
                        image: require('@/assets/images/demo/portfolio.png'),
                        imageLight: require('@/assets/images/demo/portfolio-light.png'),
                        title: '拍拍贷'
                    },
                ]
            }
        }
    }
</script>