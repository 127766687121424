<template>
    <div class="copyright-area copyright-style-one variation-two">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-7 col-sm-12 col-12">
                    <div class="copyright-left">
                        <ul class="ft-menu link-hover">
                            <li><router-link to="#">隐私政策</router-link></li>
                            <li><router-link to="#">条款条件</router-link></li>
                            <li><router-link to="/contact">联系我们</router-link></li>
                            <li><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2023005125号-1</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-5 mt_sm--20">
                    <div class="copyright-center text-center">
                        <ul class="social-icon social-default color-lessdark justify-content-center">
                            <li>
                                <el-tooltip placement="top" effect="light">
                                    <div slot="content">
                                        <div class="row">
                                            <img :src="wechat" />
                                        </div>
                                    </div>
                                    <a href="#" target="_blank" title="公众号"><svg-wechat theme="outline" size="24" /></a>
                                </el-tooltip>
                            </li>
                            <li>
                                <el-tooltip placement="top" class="" effect="light">
                                    <div slot="content">
                                        <div class="row text-center">
                                            <div class="col-6 ">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <svg-female theme="outline" size="15" fill="green" />
                                                        <a href="javascript:;">澜澜</a>
                                                    </div>
                                                    <div class="col-12">
                                                        <img :src="wechat">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="row p-3">
                                                    <div class="col-12">
                                                        <svg-male theme="outline" size="15" fill="red" />
                                                        <a href="javscript:;">栗栗</a>
                                                    </div>
                                                    <div class="col-12">
                                                        <img :src="wechat">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="#" target="_blank" title="客服"><svg-customer theme="outline" size="24" /></a>
                                </el-tooltip>
                            </li>
                            <li>
                                <el-tooltip place="top" effect="light">
                                    <div slot="content">
                                        <div class="row g-2 ms-4">
                                            <div class="col-12">
                                                <svg-headset-one theme="outline" size="18" /> <a
                                                    href="mailto:sales@lanli.cc">sales@lanli.cc(销售部)</a>
                                            </div>
                                            <div class="col-12">
                                                <svg-weixin-market theme="outline" size="18" /> <a
                                                    href="mailto:purchase@lanli.cc">purchase@lanli.cc(采购部)</a>
                                            </div>
                                            <div class="col-12">
                                                <svg-terminal theme="outline" size="18" /> <a
                                                    href="mailto:technical@lanli.cc">technical@lanli.cc(技术部)</a>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="#" target="_blank" title="邮箱"><svg-mail theme="outline" size="24" /></a>
                                </el-tooltip>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
                    <div class="copyright-right text-center text-lg-center">
                        <div>
                                    <a target="_blank"
                                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602007207"
                                        style="display:inline-block;height:15px;line-height:15px;"><img
                                            src="https://lanli-1309653011.cos.ap-shanghai.myqcloud.com/icons/beian.png" style="float:left;height:15px;line-height:15px;" />
                                        <p
                                            style="float:left;height:15px;line-height:15px;">
                                            沪公网安备 31010602007207号</p>
                                    </a>
                                </div>
                        <p class="copyright-text">
                            所有权保留 © {{ new Date().getFullYear() }} lanli.cc
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '../../icon/Icon'

export default {
    name: 'CopyrightTwo',
    components: { Icon },
    props: {
        wechat: {
            type: String,
            default() {
                return require(`@/assets/images/contact/qrcode_for_gh_2fa43fba8d33_258.jpg`)
            }
        }
    }
}
</script>