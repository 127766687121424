<template>
    <Layout>
        <!-- Start Contact Area  -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb--40">
                        <SectionTitle text-align="center" subtitle="About Us" title="关于我们" />
                    </div>
                </div>
                <div class="row row--15 p-5">
                    <div class="col-lg-12">
                        <div class="rn-contact-address mt_dec--30">
                            <div class="col-12">
                                <div class="rn-address">
                                    澜栗是一家在供应链管理深耕多年的公司，我们以最少的成本，令供应链从采购开始，到满足最终客户的需求。同时我们是一个有爱的大家庭，公司氛围浓厚，欢迎志同道合的小伙伴加入噢~
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Separator />
                <div class="row p-5">
                    <div class="col-lg-12 mb--40">
                        <SectionTitle text-align="center" subtitle="Contact Form" title="联系我们" />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12">
                        <div class="rn-contact-address mt_dec--30">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="headphones" :size="40" />
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">联系电话</h4>
                                            <p><a href="tel:+444 555 666 777">+021 18521530797</a></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="mail" :size="40" />
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">邮箱地址</h4>
                                            <p><a href="mailto:candy.lu@lanli.cc">candy.lu@lanli.cc</a></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="map-pin" :size="40" />
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">公司地址</h4>
                                            <p>上海市静安区闻喜路930弄14号101室</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt--40 row--15">
                    <div class="col-lg-7">
                        <ContactForm />
                    </div>
                    <div class="col-lg-5 mt_md--30 mt_sm--30">
                        <AMap />
                    </div>
                </div>
            </div>
        </div>
        <!-- End Contact Area  -->
    </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Icon from "../components/icon/Icon";
import AMap from "../components/elements/contact/Amap";
import ContactForm from "../components/elements/contact/ContactForm";
import Separator from '../components/elements/separator/Separator'

export default {
    name: 'ContactPage',
    components: { ContactForm, AMap, Icon, SectionTitle, Breadcrumb, Layout, Separator }
}
</script>